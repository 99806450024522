import { useEffect } from "react";
import { useState } from "react";
import {
  Switch,
  BrowserRouter as Router,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./Components/app/auth/login/Login";
import SignUp from "./Components/app/auth/signUp/SignUp";
import HomePage from "./Components/app/home/HomePage";
// import Navbars from "./Components/app/Navbar/Navbars";
import Navbars from "./Components/app/Navbar/Navbar";
import axios from "axios";
import UpdateState from "./context/updateingComponent/UpdateState";
import About from "./Components/app/about/About";
// import PrivateRoute from "./Components/ProtectedRoute/ProtectedRoute";

function App() {
  // const fakeAuth = {
  //   isAuthenticated: false,
  //   authenticate(cb){
  //     this.isAuthenticated = true
  //     setTimeout(cb, 100)
  //   },
  //   signout(cb){
  //     this.isAuthenticated = false
  //     setTimeout(cb, 100)
  //   }
  // }

  // function PrivateRoute ({children, ...rest }) {
  //   const fakeAuth = {
  //       isAuthenticated: false,
  //       authenticate(cb){
  //         this.isAuthenticated = true
  //         setTimeout(cb, 100)
  //       },
  //       signout(cb){
  //         this.isAuthenticated = false
  //         setTimeout(cb, 100)
  //       }
  //     }

  //   console.log(fakeAuth)
  //   return(
  //       <Route {...rest} render={()=> {
  //           return fakeAuth.isAuthenticated === true
  //           ? children
  //           : <Redirect to='/login'/>
  //       }} />

  //   )
  // }

  const [token, setToken] = useState();

  const user = localStorage.getItem("user");
  useEffect(() => {
    setToken(user);
  }, [token]);
  if (!token) {
    return (
      <>
        <Router>
          <Switch>
            <Route exact path="/signup">
              <SignUp setToken={setToken} />
            </Route>
            <Route exact path="/">
              <Login setToken={setToken} />
            </Route>
            <Route exact path="/login">
              <Login setToken={setToken} />
            </Route>
          </Switch>
        </Router>
      </>
    );
  }
  return (
    <UpdateState>
      <Router>
        <Navbars setToken={setToken} />
        <Switch>
          {/* <PrivateRoute 
          path='/home'>
        <HomePage />
        </PrivateRoute> */}

          <Route exact path="/" component={HomePage} />
          <Route exact path="/about" component={About} />

          {/* <Route exact path="/login">
          <Login />
        </Route> */}

          {/* <Route exact path="/signup" component={SignUp} /> */}
        </Switch>
      </Router>
    </UpdateState>
  );
}

export default App;
