import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./signup.css";
import TapEXlogo1 from "../../../../assets/logos/TapEXlogo1.png";
import axios from "axios";

const SignUp = ({setToken}) => {
  const intialValue = {
    fname: "",
    lname: "",
    email: "",
    password: "",
    cf_password: "",
  };
const history = useHistory();
  const [formData, updateFormData] = useState(intialValue);

  const handleChange = (e) => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value,
    });
  };
const url = "https://www.imma-go.com/profile/api/add-new-User"
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      formData.email !== "" &&
      formData.fname !== "" &&
      formData.lname !== "" &&
      formData.password !== ""
    ) {
      if (
        formData.password === formData.cf_password &&
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/.test( formData.password)
      ) {
        // console.log(formData);
        const obj = {
          "firstName": formData.fname,
          "lastName": formData.lname,
          "UserName": formData.email,
          "Password": formData.password,
          "email": formData.email
        }
         await axios.post(url, obj).then((response)=>{
          if(response.data === "user already exist."){
            alert("user already exist.")
          } else {
console.log(response.data)
            alert('Registration Successfull!')
            localStorage.setItem("userName", response.data.name);
            localStorage.setItem('user',response.data.userId);
            const userSignup = localStorage.getItem('user');
           setToken(userSignup);
           history.push('/')
            updateFormData({
              fname: "",
              lname: "",
              email: "",
              password: "",
              cf_password: "",
            });
          }
        })
      } else {
        alert("Password must be longer than 6 characters, and contain 1 uppercase, 1 lowercase, 1 number and 1 special character or Possword did not match");
      }
    }
    // ... submit to API or something
  };

  return (
    <div className="signup-main-container">
      <form onSubmit={handleSubmit} className="signup-form" id="signup-form">
        <div className="signup-logo">
          <img src={TapEXlogo1} alt="" />
        </div>
        <div className="icon-signup-container">
          <i className="fa-regular fa-user signup-icon " />
          <input
            type="text"
            name="fname"
            value={formData.fname}
            onChange={handleChange}
            className="signup-fname"
            placeholder="First Name"
          />
        </div>
        <div className="icon-signup-container">
          <i className="fa-regular fa-user signup-icon " />
          <input
            type="text"
            name="lname"
            value={formData.lname}
            onChange={handleChange}
            className="signup-lname"
            placeholder="Last Name"
          />
        </div>

        <div className="icon-signup-container">
          <i className="fa-regular fa-envelope signup-icon" />
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="signup-email"
            placeholder="Email"
          />
        </div>
        <div className="icon-signup-container ">
          <i className="fa-sharp fa-solid fa-lock signup-icon " />
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            className="signup-password"
            placeholder="Password"
            autoComplete="new-password"
          />
        </div>
        <div className="icon-signup-container">
          <i className="fa-sharp fa-solid fa-lock signup-icon " />
          <input
            type="password"
            name="cf_password"
            value={formData.cf_password}
            onChange={handleChange}
            className="signup-cf-password"
            placeholder="Confirm Password"
          />
        </div>
        <button type="submit" className="signup-btn">
          Submit
        </button>
        <p className="sign-up-link">
          Already have account?{" "}
          <span>
            <Link to="/login">Login</Link>
          </span>
        </p>
      </form>
    </div>
  );
};

export default SignUp;
