import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import axios from "axios";



const SocialModal = (props) => {
  
  const url = "https://www.imma-go.com/profile/api/insertTag";

  let userId = localStorage.getItem("user");
  let userName = localStorage.getItem("userName");
  let linkName = localStorage.getItem("linkName");

  const initialValue = {
    name: "",
    link: "",
  };

  let validateUrl =
    /^(?:(?:https?|ftp|Https|HTTPS|http|HTTP|Http):\/\/)+(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!132\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[A-Za-zA-Z\u00a1-\uffff0-9]-*)*[A-Za-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[A-Za-zA-Z\u00a1-\uffff0-9]-*)*[A-Za-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[A-Za-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  let regEmail =
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  const [componentMount, setComponentMount] = useState([]);
  const [state, setState] = useState(initialValue);
  const handleSubmit = async (e) => {
    e.preventDefault();
    const obj = {
      userName: userName,
      name: linkName,
      linkName: state.name,
      userId: userId,
      data:
   
        linkName === "contact" ||
        linkName === "emergContact"
          ? state.link.length < 4 || state.link.length > 15
            ? alert("Enter valid number! \n e.g +92*********** ")
            : state.link
          : linkName === "facebook" ||
          linkName === "link" ||
            linkName === "twitter" ||
            linkName === "instagram" ||
            linkName === "snapchat" ||
            linkName === "youtube" ||
            linkName === "linkedin" ||
            linkName === "pinterest" ||
            linkName === "soundcloud" ||
            linkName === "spotify" ||
            linkName === "tiktok"
          ? validateUrl.test(state.link) === false
            ? alert(
                "Enter valid username/link! \n e.g https://www." +
                  linkName +
                  ".com/*****"
              )
            : state.link
          : linkName === "paypal" || linkName === "email"
          ? regEmail.test(state.link) === false
            ? alert("Enter valid Email! \n e.g ******@******.com")
            : state.link
          :    linkName === "whatsapp" ?
          state.link.length < 4 || state.link.length > 15 ? 
          alert("Enter valid number! \n e.g +92*********** ")
          :  ` https://wa.me/${state.link}`: "",
    };

    if (obj.data !== undefined) {
      await axios.post(url, obj).then((response) => {
        if (response.data === "data inserted") {
          props.toggle();
          setComponentMount(response.data);
          setState({
            name: "",
            link: "",
          });
        } else {
          alert("network error");
        }
      });
    }
  };

  useEffect(() => {
    props.refreshFunction();
  }, [componentMount]);

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Modal className="main-modal" show={props.isShow}>
      <ModalHeader>
        <div className="back-to-modal">
          <KeyboardBackspaceIcon
            style={{ cursor: "pointer" }}
            onClick={props.toggle}
          />
        </div>
        <div>Choose a link</div>
        <div>
          <CloseIcon style={{ cursor: "pointer" }} onClick={props.toggle} />
        </div>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="all-links-profile-pic">
            <img
              src={props.image}
              className="all-links-logo"
              name="myImage"
              alt="contact-pic"
            />
          </div>
          <div className="all-links-form">
              
            <div className="socialModal-name" >
              {state.name && <div className="socialModal-label"> <label> Username </label> </div>}
            <input
            className="all-links-name"
              placeholder={props.profileText}
              name="name"
              value={state.name}
              onChange={handleChange}
              type="text"
            />
            </div>
            <div className="socialModal-link">
            {state.link && <div className="socialModal-label" > <label> Profile Link </label> </div>}

            <input
              className="all-links-name"
              placeholder={props.discriptionText}
              name="link"
              value={state.link}
              onChange={handleChange}
              type="text"
            />
            </div>
            <button className="all-link-btn">Save</button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default SocialModal;
