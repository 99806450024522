import React, { useState, useEffect } from "react";
import img1 from "../../../assets/img1.jpeg";
import "./homepage.css";
import axios from "axios";
import EditFormModal from "./formModal/EditFormModal";
import AddLinksPage from "./LinksHomePage/AddLinksPage";
import { Link } from "react-router-dom";
import updateContext from "../../../context/updateingComponent/updateContext";
import bg from "../../../assets/bg.webp";
import profile from "../../../assets/profile.jpg";
import { useContext } from "react";
const HomePage = () => {
  const update = useContext(updateContext);

  const [isShow, invokeModal] = React.useState(false);
  const initModal = () => {
    return invokeModal(!isShow);
  };

  useEffect(() => {
    document.title=("Tap-ex")
  }, [])
  useEffect(() => {
    update.fetchUser();
  }, [isShow]);

  // const bgImagurl = {bgImagurl !== null ? bgImagurl : "https://w0.peakpx.com/wallpaper/35/260/HD-wallpaper-background-bg-textures-purple-abstract-pink.jpg"}
  const profileImage =
    "https://www.imma-go.com/profile/public/images/" +
    update.fetchedData.imagePath;
  return (
    <>
      <section className="hero" >
        <div className="homepage-container">
          <div
            className="bg-img"
            style={{
              backgroundImage: `linear-gradient(to bottom, transparent, black ), url(${
                !update.fetchedData.bgImage
                  ? bg
                  : "https://www.imma-go.com/profile/public/images/" +
                    update.fetchedData.bgImage
              })`,
              backgroundSize: "cover",
              backgroundRepeat: "no-repeat",
              backgroundPosition: "center",
            }}
          >
            {/* <img src= {!update.fetchedData.bgImage ? bg : `url(${bg})` "https://www.imma-go.com/profile/public/images/" +update.fetchedData.bgImage}  alt="bg-img" /> */}
      <div className="mainContainer">
          <div className="form-container">
            <div className="profile-pic-container">
              <img
                className="profile-pic"
                src={update.fetchedData.imagePath ? profileImage : profile}
                alt="profile pic"
              />
            </div>
            <div className="user-name " style={{ fontWeight: "bold" }}>
              {update.fetchedData.first_Name} {update.fetchedData.last_Name}
            </div>

            <div className="edit-form-btn">
              {" "}
              <button className="edit-btn" onClick={initModal}>
                {" "}
                <i className="fa-sharp fa-solid fa-pen" /> Edit Contact Details
              </button>{" "}
            </div>

            <EditFormModal
              isShow={isShow}
              initModal={initModal}
              invokeModal={invokeModal}
            />

            <div className="contact-details-btn">
              <div className="call-btn">
                <span style={{ marginBottom: "5px" }}>Phone Number:</span>{" "}
                <br />{" "}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {update.fetchedData.phoneNo1}
                </span>
              </div>
              <div className="email-btn">
                <span style={{ marginBottom: "5px" }}>E-mail: </span> <br />{" "}
                <span style={{ fontWeight: "bold" }}>
                  {" "}
                  {update.fetchedData.email1}
                </span>
              </div>
            </div>
          </div>
        <AddLinksPage />
          </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomePage;
