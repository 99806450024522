import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import "./addlinkspage.css";
import LinksList from "./linksList/LinksList";
import LinksModal from "./LinksModal/LinksModal";
import pinterest from "../../../../assets/Icons/pinterest.png";
import youtube from "../../../../assets/Icons/youtube.png";
import instagram from "../../../../assets/Icons/instagram.png";
import whatsapp from "../../../../assets/Icons/whatsapp.png";
import facebook from "../../../../assets/Icons/facebook.png";
import linkedin from "../../../../assets/Icons/linkedin.png";
import email from "../../../../assets/Icons/email.png";
import link from "../../../../assets/Icons/link.png";
import emergancyContact from "../../../../assets/Icons/emergancyContact.png";
import paypal from "../../../../assets/Icons/paypal.png";
import snapchat from "../../../../assets/Icons/snapchat.png";
import spotify from "../../../../assets/Icons/spotify.png";
import twitter from "../../../../assets/Icons/twitter.png";
import medicalInfo from "../../../../assets/Icons/medicalInfo.png";
import soundcloud from "../../../../assets/Icons/soundcloud.png";
import tiktok from "../../../../assets/Icons/tiktok.png";
import { useContext } from "react";
import updateContext from "../../../../context/updateingComponent/updateContext";
const AddLinksPage = () => {

  const update = useContext(updateContext)
  // links modal
  const [isShow, setIsShow] = useState(false);
  const toggle = () => {
    return setIsShow(!isShow);
  };
  //fetching tags
  const [direct, setDirect] = useState("");
  const [tags, setTags] = useState([]);
  const fetchingUrl = "https://www.imma-go.com/profile/api/UserTagsData";
  const directOnOff = "https://www.imma-go.com/profile/api/directOnOff";
  let userId = localStorage.getItem("user");
  const fetchTagData = async () => {
    const req = await axios.post(fetchingUrl, { userId });
   
    setTags(req.data.User[0].Tags);
    setDirect(req.data.User[0]);
  };
  const [updateComponent, setUpdateComponent] = useState({});

  const handleDirectOnOff = async () => {
  
    const obj = {
      userId: userId,
      direct: direct.direct === "off" ? "on" : "off",
      name: direct.name,
      directPriority: direct.directPriority,
      Tags: direct.Tags,
    };
   
    const res = await axios.post(directOnOff, obj);
    
    setUpdateComponent(res)
  };
  useEffect(() => {
    fetchTagData()
  },[updateComponent, update.deleteRes]) 

  return (
    <div className="links-main-container">
      <div className="links-container">
        <div className="links-header">
          <div className="left-links-header">
            <p style={{ marginBottom: "5px ", fontSize: "14px" }}>My Links</p>
            <p
              onClick={handleDirectOnOff}
              style={{
                margin: "0",
                fontSize: "14px",
                fontWeight: "700",
                cursor: "pointer",
              }}
            >{`Direct ${direct.direct === "off" ? "off" : "on"}`}</p>
          </div>
          <div className="right-link-header">
            <button className="add-links-btn" onClick={toggle}>
              {" "}
              Add a Link
            </button>
            <LinksModal refreshFunction={fetchTagData} isShow={isShow} toggle={toggle} />
          </div>
        </div>
      </div>
      <div className="links-added">
      

        {tags.map((tagData, index) => {
          let images;
          if (tagData.name === "whatsapp") {
            images = whatsapp;
          } else if (tagData.name === "instagram") {
            images = instagram;
          } else if (tagData.name === "pinterest") {
            images = pinterest;
          } else if (tagData.name === "facebook") {
            images = facebook;
          } else if (tagData.name === "youtube") {
            images = youtube;
          } else if (tagData.name === "linkedin") {
            images = linkedin;
          } else if (tagData.name === "medicalInfo") {
            images = medicalInfo;
          } else if (tagData.name === "soundcloud") {
            images = soundcloud;
          } else if (tagData.name === "spotify") {
            images = spotify;
          } else if (tagData.name === "tiktok") {
            images = tiktok;
          } else if (tagData.name === "email") {
            images = email;
          } else if (tagData.name === "twitter") {
            images = twitter;
          } else if (tagData.name === "emergContact") {
            images = emergancyContact;
          } else if (tagData.name === "snapchat") {
            images = snapchat;
          } else if (tagData.name === "paypal") {
            images = paypal;
          } else if (tagData.name === "link") {
            images = link;
          }

          return (
            <div key={index}>
              <LinksList  refreshFunction={fetchTagData} tags={tagData} image={images} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default AddLinksPage;
