import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";
import "./myaccount.css";

const MyAccount = ({ isShow, initModal }) => {
  const url = "https://www.imma-go.com/profile/api/UserById";
  const updateUrl = "https://www.imma-go.com/profile/api/UpdateUserInfo";

  const intialValue = {
    name: "",
    newPassword: "",
    cf_newPassword: "",
    oldPassword: "",
  };
  const [fetchedData, setFetchedData] = useState({});
  const [response, setResponse] = useState({});
  const [editFormData, setEditFormData] = useState(intialValue);
  const fetchUser = async () => {
    let userId = localStorage.getItem("user");
    const res = await axios.post(url, { userId: userId });
    setEditFormData(res.data);
    setFetchedData(res.data);
  };
  const handleChange = (e) => {
    setEditFormData({
      ...editFormData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (
      editFormData.userName !== "" ||
      editFormData.Password !== "" ||
      editFormData.cf_newPassword !== "" ||
      editFormData.newPassword !== ""
    ) {
      if (
        /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{6,}$/.test(
          editFormData.newPassword
        )
      ) {
        if (editFormData.newPassword === editFormData.cf_newPassword) {
          if (editFormData.oldPassword !== editFormData.Password) {
            const obj = {
              UserName: editFormData.name,
              Password: editFormData.newPassword,
              userId: editFormData.userId,
              Bio: editFormData.Bio,
              address: editFormData.address,
              bgImage: editFormData.bgImage,
              city: editFormData.city,
              country: editFormData.country,
              email1: editFormData.email1,
              email2: editFormData.email2,
              first_Name: editFormData.first_Name,
              gender: editFormData.gender,
              imagePath: editFormData.imagePath,
              last_Name: editFormData.last_Name,
              otp: editFormData.otp,
              phoneNo1: editFormData.phoneNo1,
              phoneNo2: editFormData.phoneNo2,
              postal_Code: editFormData.postal_Code,
              profile_Name: editFormData.profile_Name,
              remember_token: editFormData.remember_token,
              status: editFormData.status,
              updated_at: editFormData.updated_at,
              created_at: editFormData.created_at,
              email_verified_at: editFormData.email_verified_at,
            };

            const res = await axios.post(updateUrl, obj);

            setResponse(res);
            initModal();
          } else {
            alert(JSON.stringify("Old Password does not match"));
          }
        } else {
          alert(
            " New Password and Confirm New Password must be same characters"
          );
        }
      } else {
        alert(
          "Password must be longer than 6 characters, and contain 1 uppercase, 1 lowercase, 1 number and 1 special character"
        );
      }
    } else {
      alert("please fill all black tabs");
    }
  };
  useEffect(() => {
    fetchUser();
  }, [response]);

  return (
    <Modal className="modal-dailog" style={{ marginTop: "5%" }} show={isShow}>
      <Modal.Body style={{ background: "black" }}>
        <CloseIcon
          style={{ color: "white", cursor: "pointer" }}
          onClick={initModal}
        />
        <form
          onSubmit={handleSubmit}
          className="accountModal-form"
          id="accountModal-form"
        >
          <div className="accountModal-bg">
            <img
              className="accountModal-bg-img"
              src={`https://www.imma-go.com/profile/public/images/${fetchedData.bgImage}`}
              alt=""
            />
          </div>
          <div className="accountModal-contact-details">
            <div className="profile-pic-accountModal">
              <img
                style={{ marginTop: "-150px" }}
                className="profile-pic-accountModal"
                src={`https://www.imma-go.com/profile/public/images/${fetchedData.imagePath}`}
                alt="profile pic"
              />
            </div>
            <div className="d-flex flex-column" style={{ marginTop: "-120px" }}>
              <div className="icon-accountModal-container row-7">
                {editFormData.name && (
                  <div className="accountModal-label">
                    {" "}
                    <label>Username </label>{" "}
                  </div>
                )}
                <i className="fa-regular fa-envelope accountModal-icon " />
                <input
                  type="text"
                  //   required
                  autoComplete="false"
                  name="name"
                  disabled
                  value={editFormData.name}
                  onChange={handleChange}
                  className="accountModal-email"
                  placeholder="User Name"
                />
              </div>
              <div className="icon-accountModal-container row-8">
                {editFormData.oldPassword && (
                  <div className="accountModal-label">
                    {" "}
                    <label>Old Password </label>{" "}
                  </div>
                )}

                <i className="fa-sharp fa-solid fa-lock accountModal-icon"></i>
                <input
                  type="password"
                  name="oldPassword"
                  value={editFormData.oldPassword}
                  onChange={handleChange}
                  className="accountModal-email2"
                  placeholder="Old Password"
                  autoComplete="new-password"
                />
              </div>
              <div className="icon-accountModal-container row-8">
                {editFormData.newPassword && (
                  <div className="accountModal-label">
                    {" "}
                    <label>New Password </label>{" "}
                  </div>
                )}

                <i className="fa-sharp fa-solid fa-lock accountModal-icon"></i>
                <input
                  type="password"
                  name="newPassword"
                  required
                  value={editFormData.newPassword}
                  onChange={handleChange}
                  className="accountModal-email2"
                  autoComplete="false"
                  placeholder="New Password"
                />
              </div>
              <div className="icon-accountModal-container row-8">
                {editFormData.cf_newPassword && (
                  <div className="accountModal-label">
                    {" "}
                    <label>Confirm Mew Password </label>{" "}
                  </div>
                )}

                <i className="fa-sharp fa-solid fa-lock accountModal-icon"></i>
                <input
                  type="password"
                  name="cf_newPassword"
                  required
                  value={editFormData.cf_newPassword}
                  onChange={handleChange}
                  className="accountModal-email2"
                  placeholder="Confirm New Password"
                  autoComplete="false"
                />
              </div>
            </div>
            <Button
              className="accountModal-save-btn"
              type="submit"
              form="accountModal-form"
            >
              Update
            </Button>
          </div>
        </form>
      </Modal.Body>
      {/* <Modal.Footer>
  <Button variant="danger" onClick={initModal}>
    Close
  </Button>
  <Button variant="dark" type="submit" form='accountModal-form' >
    Store
  </Button>
</Modal.Footer> */}
    </Modal>
  );
};

export default MyAccount;
