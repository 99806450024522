import React from "react";
import MyAccount from "./myAccount/MyAccount";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../../../assets/logos/logo.png";
import { Link } from "react-router-dom";
import "./navbar.css";
const Navbars = ({ setToken }) => {
  const [isShow, invokeModal] = React.useState(false);
  const initModal = () => {
    return invokeModal(!isShow);
  };
  let userName = localStorage.getItem("userName");

  const mainUrl = "https://www.imma-go.com/profile/";
  return (
    // <nav>
    //     <div className="left-side-nav">
    //     Tech Melo
    //     </div>
    //     <div className="right-side-nav">
    //         <ul>
    //             <li>Home</li>
    //             <li onClick={initModal}>My Account </li>
    //             <MyAccount isShow={isShow} initModal={initModal} />
    //             <li>My Profile Link</li>
    //             <li>Activate</li>
    //             <li>How to share your Profile</li>
    //             <li onClick={()=>{setToken(localStorage.clear())}}>Sign Out</li>
    //         </ul>
    //     </div>
    //     </nav>

    <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
      <Container>
        <Navbar.Brand style={{ cursor: "pointer" }}>
          {" "}
          <Nav.Link>
            {" "}
            <Link to="/" className="homeLink">
              {" "}
              <img src={logo} alt="Tap-ex" style={{ width: "50px" }} />
            </Link>{" "}
          </Nav.Link>{" "}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse
          id="responsive-navbar-nav "
          className="justify-content-end"
        >
          <Nav className="me-auto">
            <Nav.Link type="button" onClick={initModal}>
              My Account
            </Nav.Link>
            <MyAccount isShow={isShow} initModal={initModal} />
            <Nav.Link href={mainUrl + userName} target="_blank">
              My Profile Link
            </Nav.Link>
            <Nav.Link href={mainUrl + userName} target="_blank">
              Activate Card
            </Nav.Link>
            <Nav.Link data-toggle="modal" data-target="#exampleModal">
              Scan your card
            </Nav.Link>
            {/* <button type="button" id="toggleModal" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal">
  Launch demo modal
</button> */}

            {/* toggle modal for scaning card text */}
            <div
              className="modal fade"
              id="exampleModal"
              tabIndex="-1"
              role="dialog"
              aria-labelledby="exampleModalLabel"
              aria-hidden="true"
            >
              <div className="modal-dialog" role="document">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">
                      Scan your card
                    </h5>
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    User can scan his card through mobile app only.
                  </div>
                  {/* <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div> */}
                </div>
              </div>
            </div>
            <Nav.Link>
              <Link to="/about" className="aboutLink">
                About us
              </Link>
            </Nav.Link>
            {/* <NavDropdown title="Dropdown" id="collasible-nav-dropdown">
                  <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.2">
                    Another action
                  </NavDropdown.Item>
                  <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                  <NavDropdown.Divider />
                  <NavDropdown.Item href="#action/3.4">
                    Separated link
                  </NavDropdown.Item>
                </NavDropdown> */}
          </Nav>
          <Nav>
            <Nav.Link
              onClick={() => {
                setToken(localStorage.clear());
              }}
            >
              Sign Out
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>

    //   <nav className="navbar navbar-expand-lg navbar-dark" style={{cursor: 'pointer', background: "black", }}>
    //   <span className="navbar-brand" >Techmelo</span>
    //   <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
    //     <span className="navbar-toggler-icon"></span>
    //   </button>
    //   <div className="collapse navbar-collapse " id="navbarNav">
    //     <ul className="navbar-nav text-white " style={{background: "black", marginTop: "-5px", paddingLeft: "20px", zIndex: "100", width: "100%", marginLeft:"-10px", position: "absolute"}}>
    //       <li className="nav-item active">
    //         <p className="nav-link" href="#">Home <span className="sr-only">(current)</span></p>
    //       </li>

    //       <li className="nav-item" onClick={initModal}>
    //         <span className="nav-link" href="#">My Account</span>
    //         <MyAccount isShow={isShow} initModal={initModal} />
    //       </li>
    //       <li className="nav-item" >
    //         <span className="nav-link" href="#">My Profile Link</span>
    //       </li>
    //       <li className="nav-item" >
    //         <span className="nav-link" >Activate</span>
    //       </li>
    //       <li className="nav-item" >
    //       <span className="nav-link" >Activate</span>
    //       </li>
    //       <li className="nav-item" onClick={()=>{setToken(localStorage.clear())}}>
    //       <span className="nav-link" href="#">How to share your Profile</span>
    //       </li>
    //       <li className="nav-link" onClick={()=>{setToken(localStorage.clear())}}>Sign Out</li>
    //     </ul>
    //   </div>
    // </nav>
  );
};

export default Navbars;
