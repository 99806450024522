import updateContext from "./updateContext";
import React from "react";
import { useState } from "react";
import axios from "axios";


const UpdateState = (props)=>{


    const [deleteRes, setDeleteRes] = useState({})

    const url = "https://www.imma-go.com/profile/api/UserById";
    const [fetchedData, setFetchedData] = useState({})
    
    const fetchUser = async () => {
      let userId = localStorage.getItem("user");
      // console.log("first", userId);
      const res = await axios.post(url, { userId: userId });
      
      setFetchedData(res.data);
     
    };

    const [direct, setDirect] = useState([]);
  const fetchingUrl = "https://www.imma-go.com/profile/api/UserTagsData";
  const fetchTagData = async () => {
    let userId = localStorage.getItem("user");
    const req = await axios.post(fetchingUrl, { userId });
    console.log("fetching tag data", req);
    setDirect(req.data.User[0]);
  };
    return(
        <updateContext.Provider value={{fetchedData, fetchUser, fetchTagData, setDeleteRes, deleteRes, direct}}>

            {props.children}
        </updateContext.Provider>
    )
}

export default UpdateState