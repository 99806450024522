import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import "./editlink.css";
import axios from "axios";
import updateContext from "../../../../../context/updateingComponent/updateContext";
import { useContext } from "react";

const EditSocialLinkModal = (props) => {
  const update = useContext(updateContext);
  const initialValue = {
    name: "",
    link: "",
  };
  const [state, setState] = useState(initialValue);

  let validateUrl =
    /^(?:(?:https?|ftp|Https|HTTPS|http|HTTP|Http):\/\/)+(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!132\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[A-Za-zA-Z\u00a1-\uffff0-9]-*)*[A-Za-zA-Z\u00a1-\uffff0-9]+)(?:\.(?:[A-Za-zA-Z\u00a1-\uffff0-9]-*)*[A-Za-zA-Z\u00a1-\uffff0-9]+)*(?:\.(?:[A-Za-zA-Z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
  let regEmail =
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

  useState(() => {
    setState({
      tagId: props.tags.id,
      linkName: props.tags.linkName,
      link: props.tags.data,
    });
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    const obj = {
      tagId: state.tagId,
      linkName: state.linkName,
      data:
        props.tags.name === "contact" ||
        props.tags.name === "whatsapp" ||
        props.tags.name === "emergContact"
          ? state.link.length < 4 || state.link.length > 15
            ? alert("Enter valid number! \n e.g +92*********** ")
            : state.link
          : props.tags.name === "facebook" ||
            props.tags.name === "twitter" ||
            props.tags.name === "instagram" ||
            props.tags.name === "snapchat" ||
            props.tags.name === "youtube" ||
            props.tags.name === "linkedin" ||
            props.tags.name === "pinterest" ||
            props.tags.name === "soundcloud" ||
            props.tags.name === "spotify" ||
            props.tags.name === "tiktok"
          ? validateUrl.test(state.link) === false
            ? alert(
                "Enter valid username/link! \n e.g https://www." +
                  props.tags.name +
                  ".com/*****"
              )
            : state.link
          : props.tags.name === "paypal" || props.tags.name === "email"
          ? regEmail.test(state.link) === false
            ? alert("Enter valid Email! \n e.g ******@******.com")
            : state.link
          : "",
      name: props.tags.name,
      hide: props.tags.hide,
    };

    const updateUrl = "https://www.imma-go.com/profile/api/updateTag";
    if (obj.data !== undefined) {
      await axios.post(updateUrl, obj).then((response) => {
        if (response.data === "Tag updated") {
          update.fetchTagData();
          setState({
            name: props.tags.name,
            link: props.tags.data
          })
          props.toggle();
        } else {
          alert("user not found");
        }
      });
    }
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value,
    });
  };

  const deleteUrl = "https://www.imma-go.com/profile/api/tag-delete";
  const deleteTags = async (id) => {
    const res = await axios.post(deleteUrl, { tagId: id });
    update.setDeleteRes(res);
    props.toggle();
  };

  return (
    <Modal className="main-modal" show={props.isShow}>
      <ModalHeader>
        <div className="back-to-modal">
          <KeyboardBackspaceIcon
            style={{ cursor: "pointer" }}
            onClick={props.toggle}
          />
        </div>
        <div>Choose a link</div>
        <div>
          <CloseIcon style={{ cursor: "pointer" }} onClick={props.toggle} />
        </div>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit}>
          <div className="all-links-profile-pic">
            <img
              src={props.image}
              className="all-links-logo"
              name="myImage"
              onChange={handleChange}
              alt="contact-pic"
            />
          </div>
          <div className="all-links-form">
          <div className="socialModal-name" >
          {state.linkName && <div className="socialModal-label"> <label> Username </label> </div>}            <input
              className="all-links-name"
              placeholder="Enter your profile name"
              name="name"
              value={state.linkName}
              onChange={handleChange}
              type="text"
            />
            </div>
            <div className="socialModal-link">
            {state.link && <div className="socialModal-label" > <label> Profile Link </label> </div>}
            <input
              className="all-links-name"
              placeholder={`${
                props.tags.linkName === "emergContact" ||
                props.tags.linkName === "contact" ||
                props.tags.linkName === "whatsapp"
                  ? "Please enter your number"
                  : "Please enter your profile link"
              }`}
              name="link"
              value={state.link}
              onChange={handleChange}
              type="text"
            />
            </div>
            <button className="all-link-btn">Save</button>
          </div>
        </form>
        <div className="delete-tag">
          <p
            className="delete-tag-icon"
            style={{ cursor: "pointer" }}
            onClick={() => {
              deleteTags(props.tags.id);
            }}
          >
            <DeleteIcon /> <span>Delete Tag </span>
          </p>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default EditSocialLinkModal;
