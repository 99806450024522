import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import link from "../../../../../assets/Icons/link.png";
import email from "../../../../../assets/Icons/email.png";
import facebook from "../../../../../assets/Icons/facebook.png";
import emergancyContact from "../../../../../assets/Icons/emergancyContact.png";
import instagram from "../../../../../assets/Icons/instagram.png";
import linkedin from "../../../../../assets/Icons/linkedin.png";
import medicalInfo from "../../../../../assets/Icons/medicalInfo.png";
// import opaLogo from "../../../../../assets/Icons/opaLogo.png";
import paypal from "../../../../../assets/Icons/paypal.png";
import pinterest from "../../../../../assets/Icons/pinterest.png";
import snapchat from "../../../../../assets/Icons/snapchat.png";
import soundcloud from "../../../../../assets/Icons/soundcloud.png";
import spotify from "../../../../../assets/Icons/spotify.png";
import tiktok from "../../../../../assets/Icons/tiktok.png";
import twitter from "../../../../../assets/Icons/twitter.png";
import whatsapp from "../../../../../assets/Icons/whatsapp.png";
import youtube from "../../../../../assets/Icons/youtube.png";
import "./linksmodal.css";
import CloseIcon from '@mui/icons-material/Close';
import SocialModal from "../SocialModal/SocialModal";
const LinksModal = ({ isShow, toggle, refreshFunction }) => {
  const [showSocialModal, setShowSocialModal] = useState(false);
  const [state, setState] = useState(null);
  const [name, setName] = useState('');
  const [profileText, setProfileText] = useState(null);
  const [discriptionText, setDescriptionText] = useState(null);
  const toggleSocialModal = (data, text1, text2, name) => {
    
    setState(data);
    setProfileText(text1);
    setDescriptionText(text2)
    setName(name)
    localStorage.setItem('linkName', name)
    setShowSocialModal(!showSocialModal);
  };

 

  return (
    <Modal show={isShow} className="links-modal-container">
      <ModalHeader>
        <p>Choose a link</p>
        <div className="close">
          <CloseIcon style={{cursor: 'pointer'}} onClick={toggle} />
         
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="links-logo-container">
          <div className="links-main">
            <div className="image-container">

            <img
              src={link}
              alt="contact"
              onClick={() => {
                toggleSocialModal(link, "Enter Your Name", "Enter Your Profile link", "link");
              }}
            />
            </div>
            <div className="image-container">
            <img
              src={whatsapp}
              alt="contact"
              onClick={() => {
                toggleSocialModal(whatsapp, "Enter Your What's app Name", "  Number with country Code ", "whatsapp");
              }}
            />
            </div>
            <div className="image-container">
            <img
              src={email}
              alt="email"
              onClick={() => {
                toggleSocialModal(email,  "Title", "Enter Your Email", "email");
              }}
            />
            </div>
            <div className="image-container">
            <img
              src={instagram}

              alt="instagram"
              onClick={() => {
                toggleSocialModal(instagram,  "Title", "Instagram username", "instagram");
              }}
            />
</div>
<div className="image-container">
  
            <img
              src={facebook}
              alt="facebook"
              onClick={() => {
                toggleSocialModal(facebook, "Title", "Enter Your Profile Link", "facebook");
              }}
            />
            </div>
            <div className="image-container">
              

            <img
              src={linkedin}
              alt="linkedin"
              onClick={() => {
                toggleSocialModal(linkedin, "Title", "Enter Your Profile Link", "linkedin");
              }}
            />
            </div>
            <div className="image-container">
           
            <img
            
              src={emergancyContact}
              alt="emergancyContact"
              onClick={() => {
                toggleSocialModal(emergancyContact, "Title", "Enter Your Emergancy Contact Number", "emergContact");
              }}
            />
            </div>
            <div className="image-container">
              
           
            <img
              src={paypal}
              alt="paypal"
              onClick={() => {
                toggleSocialModal(paypal, "Title", "Enter Your Profile Link", "paypal");
              }}
            />
</div>
<div className="image-container">
  
            <img
              src={pinterest}
              alt="pinterest"
              onClick={() => {
                toggleSocialModal(pinterest, "Title", "Enter Your Profile Link", "pinterest");
              }}
            />
            </div>
<div className="image-container">

            <img
              src={snapchat}
              alt="snapchat"
              onClick={() => {
                toggleSocialModal(snapchat, "Title", "Enter Your Profile Link", "snapchat");
              }}
            />
</div>
<div className="image-container">

            <img
              src={spotify}
              alt="spotify"
              onClick={() => {
                toggleSocialModal(spotify, "Title", "Add spotify profile link", "spotify");
              }}
            />
      </div>
<div className="image-container">
            <img
              src={twitter}
              alt="twitter"
              onClick={() => {
                toggleSocialModal(twitter, "Add username", "Add twitter profile link", "twitter");
              }}
            />
            </div>
<div className="image-container">
            <img
              src={youtube}
              alt="youtube"
              onClick={() => {
                toggleSocialModal(youtube, "Title", "Enter Your Profile Link", "youtube");
              }}
            />
</div>
<div className="image-container">

            <img
              src={medicalInfo}
              alt="medicalInfo"
              onClick={() => {
                toggleSocialModal(medicalInfo, "Add your name", "Add medical info Profile Link", "medicalInfo");
              }}
            />
      </div>
<div className="image-container">
            <img
              src={soundcloud}
              alt="soundcloud"
              onClick={() => {
                toggleSocialModal(soundcloud, "Add account name", "Add sound cloud profile link", "soundcloud");
              }}
            />
</div>
<div className="image-container">

            <img
              src={tiktok}
              alt="tiktok"
              onClick={() => {
                toggleSocialModal(tiktok, "Add account name", "Add tiktok Profile Link", "tiktok");
              }}
            />
            </div>
            <SocialModal
              refreshFunction={refreshFunction}
              toggle={toggleSocialModal}
              isShow={showSocialModal}
              image={state}
              name={name}
              profileText={profileText}
              discriptionText={discriptionText}
            />
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default LinksModal;
