import "./linklist.css";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import CreateIcon from "@mui/icons-material/Create";
import { useState } from "react";
import EditSocialLinkModal from "../EditLinkModal/EditSocialLinkModal";
import axios from "axios";

const LinksList = ({ tags, index, image }) => {
  const [showEditSocialModal, setShowEditSocialModal] = useState(false);
  const toggleEditSocialModal = (text1, text2) => {
    setShowEditSocialModal(!showEditSocialModal);
  };
  const url = "https://www.imma-go.com/profile/api/updateTag";
console.log(tags)
  const [eye, seteye] = useState(tags.hide);
  const handleVisible = async (tag) => {
    if (eye === "hide") {
      //   setVisible(!visible);
      seteye("show");
      const obj = {
        tagId: tag.id,
        hide: "show",
        linkName: tag.linkName,
      };

      await axios.post(url, obj);
      // .then((response)=>{
      //   if(response.data === "Tag updated"){

      //   }
      // });
    } else if (eye === "show") {
      seteye("hide");
      // setVisible(!visible);

      const obj = {
        tagId: tag.id,
        hide: "hide",
        linkName: tag.linkName,
      };
      await axios.post(url, obj);
    }
  };

  //   const fetch = async ()=>{
  //     await axios.post(url)
  //   }
  // useEffect(() => {
  //   LinksList()
  // }, [handleVisible, eye])

  // const Eye= async ()=>{
  //     if(tags.hide=="show"){
  //         seteye(false);
  //         const obj = {
  //                     tagId: tags.id,
  //                     hide: "hide",
  //                   };

  //                  await axios.post(url, obj);

  //                 } else {
  //                     seteye(true)
  //                   const obj = {
  //                     tagId: tags.id,
  //                     hide: "show",
  //                   };
  //                    await axios.post(url, obj);
  //                 }
  // }

  // if(tags.linkName === "pinterest" || tags.linkName === "whatsapp"){
  //     imageIcon
  // }
  return (
    <div className="link-list-container" key={index}>
      <div className="left-link">
        <div className="link-logo">
          <img src={image} alt="" />
        </div>
        <div className="userName">
          <p>{tags.linkName}</p>
        </div>
      </div>
      <div className="right-link">
        <div className="eye">
          {/* <i onClick={Eye} className={`fa ${eye ? "fa-eye-slash" : "fa-eye" }`}></i> */}
          {eye === "show" ? (
            <RemoveRedEyeIcon
              onClick={() => {
                handleVisible(tags);
              }}
            />
          ) : (
            <VisibilityOffIcon
              onClick={() => {
                handleVisible(tags);
              }}
            />
          )}
        </div>
        <div className="edit-link-btn">
          <CreateIcon
            style={{ cursor: "pointer" }}
            onClick={() => {
              toggleEditSocialModal();
            }}
          />
          <EditSocialLinkModal
            isShow={showEditSocialModal}
            tags={tags}
            image={image}
            toggle={toggleEditSocialModal}
            profileText={showEditSocialModal}
          />
        </div>
      </div>
    </div>
  );
};

export default LinksList;
