import React,{useState} from "react";
import { Link, useHistory } from "react-router-dom"
import "./login.css";
import TapEXlogo1 from "../../../../assets/logos/TapEXlogo1.png";
import axios from "axios";
const Login = ({setToken}) => {

  const initialValue = {
    name: '',
    Password: ''
  }
  const url = 'https://www.imma-go.com/profile/api/loginUser'
  const [loginData, setLoginData] = useState(initialValue);




const history = useHistory()
  const handleChange = (e)=>{
    setLoginData({
      ...loginData,
      [e.target.name]: e.target.value
    });
  };
const handleSubmit = async (e)=>{
  e.preventDefault();
  if(loginData.name !== '' && loginData.Password !== ''){
    if(loginData.Password.length >= 6){
      const obj= {
        "name": loginData.name,
        "Password": loginData.Password
      }
      const res = await axios.post(url, obj)
      
      if(res?.data !== 'user not found'){
        // console.log("respone in login", res.data)
        localStorage.setItem("user", res.data.User[0].userId);
        localStorage.setItem("userName", res.data.User[0].name);
        let user = localStorage.getItem('user')
        // console.log(user)

        history.push('/')
        setToken(user)
      } else{
        alert('Username or Password is Invalid!')
      }

      // console.log(loginData)
  
    } else {
      alert('password should not less than 6 characters')
    }
  } else{
    alert('error')
  }
}
  return (
    <div className="login-main-container">
  
        <form onSubmit={handleSubmit} className="login-form" id="login-form">
          <div className="login-logo">
            <img src={TapEXlogo1} alt="" />
          </div>
          <div className="icon-login-container">
            <i className="fa-regular fa-user login-icon " />
            <input type="text" name="name" value={loginData.name} onChange={handleChange} className="login-email" placeholder="UserName" />
          </div>
          <div className="icon-login-container">
          <i className="fa-sharp fa-solid fa-lock login-icon"></i>
            <input
              type="password"
              name="Password"
              value={loginData.Password}
              onChange={handleChange}
              className="login-password"
              placeholder="Password"
              autoComplete="new-password"
            />
          </div>
          <div style={{ width: "100%" }}>
            <button type="submit" className="login-btn" >Login</button>
          </div>
          <p className="reset-password">Reset Password</p>
          <p className="sign-up-link">
            Don't have account?
            <span>
              <Link to="/signup"> Sign Up </Link>
            </span>
          </p>
        </form>
      
    </div>
  );
};

export default Login;
