import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import "./editFormModal.css";
import bg from "../../../../assets/bg.webp"
import profile from "../../../../assets/profile.jpg"
import CloseIcon from "@mui/icons-material/Close";
import axios from "axios";

const EditFormModal = ({ isShow, initModal }) => {
  const intialValue = {
    first_Name: "",
    last_Name: "",
    email1: "",
    email2: "",
    status: "",
    Bio: "",
    phoneNo1: "",
    phoneNo2: "",
    address: "",
    postal_Code: "",
    city: "",
    country: "",
  };

  const url = "https://www.imma-go.com/profile/api/UserById";
  const [editFormData, setEditFormData] = useState(intialValue);
  const [response, setResponse] = useState({});
  // const [image, setImage] = useState('')
  const fetchUser = async () => {
    let userId = localStorage.getItem("user");

    const res = await axios.post(url, { userId: userId });

    setEditFormData(res.data);
    // setImage(res.data)
  };

  const updateUrl = "https://www.imma-go.com/profile/api/UpdateUserInfo";

  const handleChange = (e) => {
    setEditFormData({
      ...editFormData,
      [e.target.name]: e.target.value,
    });
  };

  const [selectedImage, setSelectedImage] = useState("");
  const [selectedBgImage, setSelectedBgImage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    let formData = new FormData();
    formData.append("userId", editFormData.userId);
    formData.append("UserName", editFormData.name);
    formData.append("Bio", editFormData.Bio);
    formData.append("status", editFormData.status);
    formData.append("first_Name", editFormData.first_Name);
    formData.append("last_Name", editFormData.last_Name);
    formData.append("city", editFormData.city);
    formData.append("country", editFormData.country);
    formData.append("address", editFormData.address);
    formData.append("postal_Code", editFormData.postal_Code);
    formData.append("phoneNo1", editFormData.phoneNo1);
    formData.append("phoneNo2", editFormData.phoneNo2);
    formData.append("email1", editFormData.email1);
    formData.append("email2", editFormData.email2);
    formData.append("Password", editFormData.Password);
    formData.append("imagePath", selectedImage);
    formData.append("bgImage", selectedBgImage);
    // console.log('FormData =', formData);

    const response = await axios.post(updateUrl, formData);

    setResponse(response);
    initModal();
  };

  useEffect(() => {
    fetchUser();
  }, [response]);

  return (
    <>
      {/* <Button variant="success" onClick={initModal}>
    Open Modal
  </Button> */}
      <Modal className="modal-dailog" show={isShow}>
        <Modal.Body className=" edit-modal ">
          <CloseIcon
            style={{ color: "white", cursor: "pointer" }}
            onClick={initModal}
          />
          <form
            onSubmit={handleSubmit}
            className="editModal-form"
            id="editModal-form"
          >
            <div className="editModal-bg">
              {!selectedBgImage && (
                <img
                  className="editModal-bg-img"
                  src={ editFormData.bgImage  ?  `https://www.imma-go.com/profile/public/images/${editFormData.bgImage}` : bg}
                  alt="backGround Pic"
                />
              )}

              {selectedBgImage && (
                <>
                  <img
                    alt="not fount"
                    className="editModal-bg-img"
                    src={URL.createObjectURL(selectedBgImage)}
                    // src={selectedBgImage}
                  />
                  <br />
                </>
              )}

              <div>
                <label htmlFor="bg-file">
                  <i className="fa-solid fa-camera image-bg-selector text-white rounded-full bg-dark  " />
                </label>
                <input
                  hidden
                  type="file"
                  id="bg-file"
                  name="myImage"
                  className="image-bg-selector"
                  onChange={(event) => {
                    setSelectedBgImage(event.target.files[0]);
                  }}
                />
              </div>
            </div>
            <div className="editModal-contact-details">
              <div className="profile-pic-editModal">
                {!selectedImage && (
                  <img
                    style={{ marginTop: "-100px" }}
                    className="profile-pic-editModal"
                    src={ editFormData.imagePath  ? `https://www.imma-go.com/profile/public/images/${editFormData.imagePath}` : profile}
                    alt="profile pic"
                  />
                )}
                <div>
                  {selectedImage && (
                    <>
                      {" "}
                      <img
                        style={{ marginTop: "-100px" }}
                        alt="not fount"
                        className="profile-pic-editModal"
                        src={URL.createObjectURL(selectedImage)}
                      />
                      <br />
                    </>
                  )}

                  <div>
                    <label htmlFor="myImage">
                      <i className="fa-solid fa-camera image-selector text-white rounded-full bg-dark  " />
                    </label>
                    <input
                      type="file"
                      hidden
                      id="myImage"
                      name="myImage"
                      className="image-selector"
                      onChange={(event) => {
                        console.log(event.target.files[0]);
                        setSelectedImage(event.target.files[0]);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="grid">
                <div className="icon-editModal-container row-1">
                { editFormData.first_Name &&  <div className="form-label">  <label >First Name</label> </div> }
                  <i className="fa-regular fa-user editModal-icon " />
                  <input
                    type="text"
                    name="first_Name"
                    value={editFormData.first_Name}
                    onChange={handleChange}
                    className="editModal-fname"
                    placeholder="First Name"
                  />
                </div>
                <div className="icon-editModal-container row-2">
                { editFormData.last_Name &&  <div className="form-label">  <label >Last Name</label> </div> }

                  <i className="fa-regular fa-user editModal-icon " />
                  <input
                    type="text"
                    name="last_Name"
                    value={editFormData.last_Name}
                    onChange={handleChange}
                    className="editModal-lname"
                    placeholder="Last Name"
                  />
                </div>

                <div className="icon-editModal-container row-3">
                { editFormData.status &&  <div className="form-label">  <label >Title</label> </div> }
                
                  <i className="fa-regular fa-pen-to-square editModal-title-icon " />
                  <input
                    type="text"
                    name="status"
                    value={editFormData.status}
                    onChange={handleChange}
                    className="editModal-title"
                    placeholder="Title"
                  />
                </div>
                <div className="icon-editModal-container row-4">
                { editFormData.Bio &&  <div className="form-label">  <label >Description</label> </div> }
                  <i className="fa-regular fa-pen-to-square editModal-icon-description" />
                  <textarea
                    rows={1}
                    cols={4}
                    type="text"
                    name="Bio"
                    value={editFormData.Bio}
                    onChange={handleChange}
                    className="editModal-description"
                    placeholder="Description"
                  />
                </div>
                <div className="icon-editModal-container row-5 ">
                { editFormData.phoneNo1 &&  <div className="form-label">  <label >Phone No 1</label> </div> }
                  <i className="fa-solid fa-phone editModal-icon " />
                  <input
                    type="number"
                    name="phoneNo1"
                    value={editFormData.phoneNo1}
                    onChange={handleChange}
                    className="editModal-phone1"
                    placeholder="Phone 1"
                  />
                </div>

                <div className="icon-editModal-container row-6">
                { editFormData.phoneNo2 &&  <div className="form-label">  <label >Phone No 2</label> </div> }

                  <i className="fa-solid fa-phone editModal-icon " />
                  <input
                    type="number  "
                    name="phoneNo2"
                    value={editFormData.phoneNo2}
                    onChange={handleChange}
                    className="editModal-phone2"
                    placeholder="Phone 2"
                  />
                </div>
                <div className="icon-editModal-container row-7">
                { editFormData.email1 &&  <div className="form-label">  <label >Email 1</label> </div> }
                  <i className="fa-regular fa-envelope editModal-icon " />
                  <input
                    type="email"
                    required
                    name="email1"
                    value={editFormData.email1}
                    onChange={handleChange}
                    className="editModal-email"
                    placeholder="E-mail 1"
                  />
                </div>
                <div className="icon-editModal-container row-8">
                { editFormData.email2 &&  <div className="form-label">  <label >Email 2</label> </div> }

                  <i className="fa-regular fa-envelope editModal-icon " />
                  <input
                    type="email"
                    name="email2"
                    required
                    value={editFormData.email2}
                    onChange={handleChange}
                    className="editModal-email2"
                    placeholder="E-mail 2"
                  />
                </div>
                <div className="icon-editModal-container grid-row-6">
                  <i className="fa-solid fa-location-dot editModal-address-icon " />
                  <div className="editModal-address">
                   {editFormData.address && <label htmlFor="address">Address</label>}
                    <input
                      type="text"
                      
                      placeholder="Address"
                      value={editFormData.address}
                      onChange={handleChange}
                      className= {`editModal-address-detail ${!editFormData.address ? "mt-4" : "asd" }`}
                      name="address"
                      id="address"
                    />
                    <div className="postal-city">
                      <div>
                      {editFormData.postal_Code &&  <label htmlFor="postal-code">Postal Code</label>} <br /> 
                        <input
                          type="number"
                          placeholder="Postal Code"
                          value={editFormData.postal_Code}
                          onChange={handleChange}
                          name="postal_Code"
                        
                          className= {`postal-code ${!editFormData.postal_Code ? "mt-2" : "asd" }`}
                          id="postal-code"
                        />
                      </div>
                      <div>
                       {editFormData.city && <label htmlFor="city">City</label> }
                        <br />
                        <input
                          type="text"
                          name="city"
                          placeholder="City"
                          value={editFormData.city}
                          onChange={handleChange}
                          id="city"
                      className= {`city ${!editFormData.city ? "mt-2" : "asd" }`}
                          
                        />
                      </div>
                    </div>
                   {editFormData.country && <label htmlFor="country">Country</label> }
                    <input
                      type="text"
                      name="country"
                      placeholder="Country"
                      value={editFormData.country}
                      onChange={handleChange}
                      id="country"
                      className= {`country ${!editFormData.country ? "mt-4" : "asd" }`}
                    />
                  </div>
                </div>
              </div>
              <Button
                className="editModal-save-btn"
                type="submit"
                form="editModal-form"
              >
                Save
              </Button>
            </div>
          </form>
        </Modal.Body>
        {/* <Modal.Footer>
      <Button variant="danger" onClick={initModal}>
        Close
      </Button>
      <Button variant="dark" type="submit" form='editModal-form' >
        Store
      </Button>
    </Modal.Footer> */}
      </Modal>
    </>
  );
};

export default EditFormModal;
